
import { useEffect, useState } from 'react';
import url from '@luohuidong/url';

import { requestMakeOrder } from '../../api/payment/requestMakeOrder';

export default function useMakeOrder(setPaying: (isPaying: boolean) => void): [string, any] {

  const [wechatPayObj, setWechatPayObj] = useState(); //微信支付参数
  const [orderId, setOrderId] = useState(''); //web 后台订单号

  useEffect(() => {
    async function makeOrder() {
      setPaying(true);
      // eslint-disable-next-line no-restricted-globals
      const { query: params } = url.parse(decodeURIComponent(location.href));
      sessionStorage.setItem('webBackstageToken', params.token);
      delete params.token;
      try {
        //请求下单，获取 web 后台订单号及微信支付参数
        const result = await requestMakeOrder(params);
        if (result.code === 0) {
          const data = result.data;
          const payObj = data && data.call.pay_channel_data;
          const orderId = data && data.order_id;
          setWechatPayObj(payObj);
          setOrderId(orderId);
        }
      } catch ({ message }) {
        setPaying(false);
        alert(message)
      }

    }
    makeOrder();
  }, []);
  return [orderId, wechatPayObj];
}
