import { post } from '../tool'
import { getBaseUrl } from './httpConfig';

interface Data {
  [index: string]: object | string | number
}

/**
 * 会员下单
 */
export async function requestMakeOrder(data: Data) {
  try {
    const result = await post(getBaseUrl() + '/order/make', {
      ...data,
      goods_id: +data.goods_id,
      buy_count: +data.buy_count,
      order_money: +data.order_money,
      order_type: +data.order_type,
    }, {
      headers: {
        Authorization: sessionStorage.getItem('webBackstageToken')
      },
    })

    const { code, msg } = result.data
    if (code !== 0) {
      throw new Error(msg || '生成订单失败，请稍后重试')
    }
    return result.data;
  } catch (error) {
    throw new Error(error.message)
  }
}
